import Link from "next/link";
import { Layout } from "~/components/Layout/Layout";

export default function Custom404() {
  return (
    <Layout
      title="404 - Page Not Found"
      description="Sorry, the page you are looking for does not exist."
    >
      <p>
        Return to the <Link href="/">homepage</Link>.
      </p>
    </Layout>
  );
}

/**
 * Errors that we can't have getInitialProps (see _error.tsx) and getServerSideProps at the same time.
 * FIXME: Uncomment this code when we have a solution for this.
 */
// export const getServerSideProps: GetServerSideProps = async (ctx) => {
//   await trackEvent(ctx, EventNames.PAGE_NOT_FOUND);
//   return { props: {} };
// };
